#veratad-frame {
    top: 0;
    left: 0;
    border: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.veratad-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
   
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.veratad-modal--visible {
    opacity: 1;
    visibility: visible;
}

.veratad-modal-box {
    background-color: white;
    width: 420px;
    height: 670px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    transform: translateY(-50px);
    transition: transform 0.2s ease-out;
    overflow: hidden;
}

.veratad-modal--visible .veratad-modal-box {
    transform: translateY(0);
}

.veratad-modal-content {
    overflow: hidden;
}

@media (max-width: 768px) {
    .veratad-modal {
        align-items: flex-start;
        padding-top: 0px;
    }

    .veratad-modal-box {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}